import {Box} from '@mui/material'
import {Route, Switch} from 'react-router-dom'
import React from 'react'
import Header from './components/Header'
import Home from './pages/Home'
import Sponsorship from './pages/Sponsorship'
import Hall from './pages/Hall'
import Upcoming from './pages/Upcoming'
import Exhibits from './pages/Exhibits'
import {isDev} from './helpers'
import Team from './pages/Team'

function App() {
  return (
    <Box>
      <Header />
      <Switch>
        <Route path='/sponsorship'>
          <Sponsorship />
        </Route>
        <Route path='/hall/:id'>{isDev() ? <Hall /> : <Home />}</Route>
        <Route path='/upcoming'>
          <Upcoming />
        </Route>
        <Route path='/team'>{isDev() ? <Team /> : <Home />}</Route>
        <Route path='/exhibits'>{isDev() ? <Exhibits /> : <Home />}</Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Box>
  )
}

export default App
