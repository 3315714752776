import PunkBG from '../images/punk-hall-bg.png'
import CuratedBG from '../images/curated-hall-bg.svg'
import ApeBG from '../images/ape-hall-bg.png'
import LootBG from '../images/loot-hall-bg.png'
import MeebitsBG from '../images/meebits-hall-bg.png'
import FreeBG from '../images/free-hall-bg.png'

export const PUNK_HALL_ID = 0
export const CURATED_HALL_ID = 1
export const APE_HALL_ID = 2
export const LOOT_HALL_ID = 3
export const MEEBITS_HALL_ID = 4
export const FREE_HALL_ID = 5

export const HALL_META: {[id: number]: any} = {
  [PUNK_HALL_ID]: {
    name: 'CryptoPunks Hall',
    bg: PunkBG
  },
  [CURATED_HALL_ID]: {
    name: 'Art Blocks Curated Hall',
    bg: CuratedBG
  },
  [APE_HALL_ID]: {
    name: 'Bored Ape Yacht Club Hall',
    bg: ApeBG
  },
  [LOOT_HALL_ID]: {
    name: 'Loot Hall',
    bg: LootBG
  },
  [MEEBITS_HALL_ID]: {
    name: 'Meebits Hall',
    bg: MeebitsBG
  },
  [FREE_HALL_ID]: {
    name: 'Free Hall',
    bg: FreeBG
  }
}
