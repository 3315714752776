import {ReactNode} from 'react'
import {useEagerConnect} from '../../hooks'

export default function Web3ReactManager({children}: {children: ReactNode}) {
  // const { active, activate } = useWeb3React()

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null
  }

  return <>{children}</>
}
