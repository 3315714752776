import React from 'react'
import {Box, Grid, Tooltip, Typography} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import {Help} from '@mui/icons-material'
import Question from '../../images/question.svg'

const Sponsorship = () => {
  return (
    <Box
      sx={{
        px: 4,
        pt: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant='h6' mb={7} display='flex' alignItems='center'>
        Sponsors
      </Typography>
      <Typography
        variant='subtitle2'
        sx={{
          px: 4,
          py: 3,
          mb: 12,
          maxWidth: '760px',
          background: 'linear-gradient(180deg, rgba(158, 158, 158, 0.2) 0%, rgba(9, 9, 9, 0) 100%)'
        }}
      >
        <Box mb={5}>
          Your support helps sustain the FLEX’s dedication to promote crypto arts and nourish the creative community.
        </Box>
        <Box mb={5}>
          Your sponsorship today plays a vital role in crypto art’s future, and we couldn’t be more grateful. Thank you.
        </Box>
        ERC20: 0xC7831ba10E13c6338C92125df9304B43862125BF
      </Typography>
      <Typography variant='h6' mb={7} display='flex' alignItems='center'>
        <Box mr={1}>Honorary Curators</Box>
        <Tooltip
          title={
            <Typography
              variant='subtitle2'
              sx={{
                '.layer': {
                  background: 'linear-gradient(90deg, #686868 -7.37%, rgba(104, 104, 104, 0) 97.12%);',
                  height: 16,
                  width: '100%',
                  ml: 1
                }
              }}
            >
              FLΞX Museum has 10 permanent seats of Honorary Curators for those who donates 20ETH and over. First come,
              first served. Each Honorary Curator will enjoy the following benefits:
              <Box ml={2}>
                <Box>1. Obtain the most premium membership</Box>
                <Box>2. Obtain a unique NFT that is specifically designed for you by FLΞX’s artists.</Box>
                <Box display='flex' alignItems='center'>
                  3. <Box className='layer' />
                </Box>
                <Box display='flex' alignItems='center'>
                  4. <Box className='layer' />
                </Box>
              </Box>
              We will gradually unlock Honorary Curators’ rights later. Once you’ve made the donation, please contact
              flexartproject@gmail.com
            </Typography>
          }
          placement='right'
          arrow
        >
          <HelpIcon />
        </Tooltip>
      </Typography>
      <Box pb={10}>
        <Grid container spacing={6} mb={6}>
          {Array.apply(null, Array(5)).map((_, i) => {
            return (
              <Grid item>
                <img src={Question} alt='question' />
              </Grid>
            )
          })}
        </Grid>
        <Grid container spacing={6}>
          {Array.apply(null, Array(5)).map((_, i) => {
            return (
              <Grid item>
                <img src={Question} alt='question' />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Sponsorship
