import React from 'react'
import {Box, Typography} from '@mui/material'
import UpcomingImg from '../../images/upcoming.svg'

const Upcoming = () => {
  return (
    <Box
      sx={{
        background: `url(${UpcomingImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 60px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: 'black',
        pr: {md: 26, xs: 2},
        '& h2 div': {
          fontFamily: "'Mate SC', serif !important"
        },
        '.large': {
          fontSize: {md: '36px', xs: '30px'}
        },
        '.small': {
          fontSize: {md: '26px', xs: '22px'}
        }
      }}
    >
      <Typography variant='h2' display='flex' alignItems='baseline'>
        <Box className='large' pt={1}>
          F
        </Box>
        <Box className='small' mr={4}>
          LEX
        </Box>
        <Box className='large' mr={4}>
          NFT
        </Box>
        <Box className='large'>C</Box>
        <Box className='small' mr={4}>
          OMING
        </Box>
        <Box className='large'>S</Box>
        <Box className='small'>OON</Box>
      </Typography>
    </Box>
  )
}

export default Upcoming
