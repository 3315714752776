import Org from '../images/org.svg'
import Github from '../images/github.svg'
import Discord from '../images/discord.svg'
import Twitter from '../images/twitter.svg'

export const getImage = (name: string) => {
  switch (name) {
    case 'org':
      return Org
    case 'github':
      return Github
    case 'discord':
      return Discord
    case 'twitter':
      return Twitter
    default:
      return ''
  }
}
