import React, {useState} from 'react'
import {Box, Button, Dialog, DialogContent, Divider, Grid, Typography} from '@mui/material'
import Logo from '../../images/logo.png'
import {NavLink} from 'react-router-dom'
import {injectedConnector} from '../../connectors'
import {useWeb3React} from '@web3-react/core'
import {ellipseAddress} from '@w3u/dapp'
import {Jazzicon} from '@ukstv/jazzicon-react'
import QuestionImage from '../../images/question.svg'

const Header = () => {
  const {account, activate} = useWeb3React()
  const [open, setOpen] = useState<boolean>(false)

  const connect = () => activate(injectedConnector, (e) => console.error(e), true)

  return (
    <Box py={2} px={4} bgcolor='#161616'>
      <Dialog open={open} onClose={() => setOpen(false)} scroll='paper'>
        <Box
          px={7}
          py={3}
          sx={{
            width: {md: '768px'}
          }}
        >
          <Typography variant='subtitle2'>Address</Typography>
          <Typography variant='h6' mb={4}>
            {ellipseAddress(account, 6)}
          </Typography>
          <Box>
            <Grid container spacing={4}>
              <Grid item md={2} xs={6}>
                <img src={QuestionImage} alt='nft' width={100} height={100} />
                <Typography variant='subtitle2'>Name</Typography>
              </Grid>
              <Grid item md={2} xs={6}>
                <img src={QuestionImage} alt='nft' width={100} height={100} />
                <Typography variant='subtitle2'>Name</Typography>
              </Grid>
              <Grid item md={2} xs={6}>
                <img src={QuestionImage} alt='nft' width={100} height={100} />
                <Typography variant='subtitle2'>Name</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          '&>a': {
            display: 'inline-block',
            height: '40px'
          }
        }}
      >
        <NavLink to='/'>
          <img src={Logo} alt='Logo' height={40} />
        </NavLink>
        <Divider orientation='vertical' variant='middle' flexItem sx={{bgcolor: '#393939', my: 1, ml: 5}} />
        <Box
          sx={{
            display: {md: 'block', xs: 'none'},
            pl: 6,
            a: {
              mr: 6,
              color: '#c6c6c6',
              '&:hover': {
                color: '#ffffff'
              }
            }
          }}
        >
          <NavLink to='/exhibits'>My Exhibits</NavLink>
          <NavLink to='/sponsorship'>Sponsorship</NavLink>
          <NavLink to='/docs'>Docs</NavLink>
          <NavLink to='/upcoming'>FLΞX Club</NavLink>
          {/*<NavLink to='/team'>Team</NavLink>*/}
        </Box>
        <Box ml='auto'>
          {account ? (
            <Box
              display='flex'
              alignItems='center'
              border='1px solid #fff'
              borderRadius={100}
              pl={3}
              pr={6}
              onClick={() => setOpen(false)}
            >
              <Box
                mr={2}
                width={32}
                maxHeight={32}
                sx={{
                  '.icon>div': {
                    height: '32px !important'
                  }
                }}
              >
                <Jazzicon address={account} className='icon' />
              </Box>
              <Box>
                <Box display='flex' alignItems='center'>
                  Ξ
                  <Typography variant='subtitle2' lineHeight='20px' ml={1}>
                    36.982
                  </Typography>
                </Box>
                <Typography variant='subtitle2' sx={{fontSize: '12px'}}>
                  {ellipseAddress(account, 4)}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Button variant='outlined' color='inherit' sx={{borderRadius: '50px'}} onClick={connect}>
              Connect Wallet
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Header
