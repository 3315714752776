import {
  CHAIN_FANTOM,
  CHAIN_FANTOM_TESTNET,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
  CHAIN_XDAI_TESTNET
} from '@w3u/chains'
import {InjectedConnector} from '@web3-react/injected-connector'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    1,
    3,
    4,
    5,
    42,
    56,
    97,
    256,
    65,
    66,
    43113,
    43114,
    CHAIN_HARMONY_0,
    CHAIN_HARMONY_0_TESTNET,
    CHAIN_HECO,
    CHAIN_POLYGON,
    CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI_TESTNET,
    CHAIN_XDAI,
    CHAIN_FANTOM,
    CHAIN_FANTOM_TESTNET
  ]
})
