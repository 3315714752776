import React from 'react'
import Banner from '../../components/Banner'
import Halls from '../../components/Halls'
import Footer from '../../components/Footer'

const Home = () => {
  return (
    <>
      <Banner />
      <Halls />
      <Footer />
    </>
  )
}

export default Home
