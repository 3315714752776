import React, {useRef, useState} from 'react'
import {Box, Typography} from '@mui/material'
import Slick from 'react-slick'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import {useWindowSize} from 'react-use'

const Banner = () => {
  const slickRef = useRef()
  const [slick, setSlick] = useState<any>()
  const [index, setIndex] = useState(0)
  const {width} = useWindowSize()

  const settings = {
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  }

  return (
    <Box
      py={3}
      px={4}
      mb={8}
      position='relative'
      sx={{
        '& .slick-slide': {
          height: `${width / 3.44}px`,
          '&>div': {
            height: '100%'
          }
        },
        '& .item': {
          display: 'flex !important',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%'
        }
      }}
    >
      <Box
        position='absolute'
        bottom={0}
        width='100%'
        height={30}
        display='none'
        alignItems='center'
        justifyContent='center'
        sx={{
          svg: {
            cursor: 'pointer'
          },
          div: {
            cursor: 'pointer',
            '&:hover, &.active': {
              borderBottom: '4px solid #0F62FE',
              mt: '4px'
            }
          }
        }}
      >
        <ArrowLeftIcon sx={{mr: 4}} />
        {Array.apply(null, Array(1)).map((_, i: number) => (
          <Box
            px={1}
            py={3}
            mx={6}
            onClick={() => slick.slickGoTo(i)}
            key={i}
            className={` ${index === i ? 'active' : ''}`}
          >
            {i + 1}
          </Box>
        ))}
        <ArrowRightIcon sx={{ml: 4}} />
      </Box>
      <Slick ref={(slickRef) => setSlick(slickRef)} afterChange={(idx) => setIndex(idx)} {...settings}>
        <Box px={{md: 20, xs: 2}} className='item'>
          <Typography variant='h2' mb={3} fontSize={{md: '60px', xs: '36px'}}>
            Flex, Create, and Earn
          </Typography>
          <Typography variant='h4' fontSize={{md: '34px', xs: '18px'}}>
            from your NFTs on the Vessel of Crypto Arts Renaissance
          </Typography>
        </Box>
      </Slick>
    </Box>
  )
}

export default Banner
