import {Web3Provider} from '@ethersproject/providers'
import {ExternalProvider, JsonRpcFetchFunc} from '@ethersproject/providers/src.ts/web3-provider'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {Web3ReactProvider} from '@web3-react/core'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Web3ReactManager from './components/Web3ReactManager'
import {defaultTheme} from './themes/default'
import {BrowserRouter} from 'react-router-dom'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ReactManager>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Web3ReactManager>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
