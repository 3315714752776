import {getImage} from './image'

export const SOCIAL_LINKS: {[name: string]: any} = {
  twitter: {
    name: 'Twitter',
    href: 'https://twitter.com/theFlexArt',
    icon: getImage('twitter')
  },
  github: {
    name: 'Github',
    href: 'https://github.com/FlEX-NFT',
    icon: getImage('github')
  },
  discord: {
    name: 'Discord',
    href: 'https://discord.com/channels/887661511772893233/887661512225869854',
    icon: getImage('discord')
  },
  instagram: {
    name: 'Instagram',
    href: 'https://www.instagram.com/theflexart/',
    icon: getImage('instagram')
  },
  org: {
    name: 'Instagram',
    href: 'https://www.instagram.com/theflexart/',
    icon: getImage('instagram')
  }
}
