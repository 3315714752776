import React from 'react'
import {Box, Button, Divider, Grid, Typography} from '@mui/material'
import Index1 from '../../images/index-1.svg'
import Index2 from '../../images/index-2.svg'
import Index3 from '../../images/index-3.svg'
import Index4 from '../../images/index-4.svg'
import Index5 from '../../images/index-5.svg'
import Index6 from '../../images/index-6.svg'
import {useWindowSize} from 'react-use'
import {NavLink} from 'react-router-dom'

const Halls = () => {
  const {width} = useWindowSize()
  const hallH = width / 4.3

  return (
    <Box
      sx={{
        '.MuiButton-root': {
          padding: '10px 50px !important'
        },
        '.divider': {
          height: '2px',
          width: '300px',
          background: '#fff',
          position: 'relative',
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            background: '#0F62FE',
            height: '2px',
            width: '159px'
          }
        },
        '& .content': {
          transition: 'all .8s'
        },
        '& .date': {
          fontSize: '13px'
        }
      }}
    >
      <Box
        py={3}
        px={4}
        position='relative'
        sx={{
          height: `${hallH}px`,
          background: `url(${Index1})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '101%',
          cursor: 'pointer',
          '&:hover': {
            '& .content': {
              opacity: 1,
              visibility: 'visible'
            }
          },
          '& .content': {
            background: '#000000',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 100,
            opacity: 0,
            visibility: 'hidden'
          }
        }}
      >
        <Box px={10} height='100%' display='flex' flexDirection='column' justifyContent='center' className='content'>
          <Typography variant='h6' mb={2}>
            CryptoPunks Hall
          </Typography>
          <Box className='divider' />
          <Typography variant='h6' mb={2}>
            00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
            <span className='date'>mins</span>
            :00
            <span className='date'>secs</span>
          </Typography>
          <Typography variant='subtitle2' mb={1}>
            Information
          </Typography>
          <Typography variant='subtitle2' mb={1}>
            Session: A01
          </Typography>
          <Typography variant='subtitle2' mb={1}>
            #Booth: 100
          </Typography>
          <Typography variant='subtitle2' mb={1}>
            #Exhibits: 0
          </Typography>
          <Box textAlign='right'>
            <Button variant='outlined' color='inherit' component={NavLink} to='/hall/0'>
              Read more
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          '.item': {
            height: `${hallH}px`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            cursor: 'pointer',
            position: 'relative',
            '&:hover': {
              '& .content': {
                opacity: 1,
                visibility: 'visible'
              }
            },
            '& .content': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: '#000000',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 100,
              opacity: 0,
              visibility: 'hidden'
            }
          }
        }}
      >
        <Box width={width / 2 + Math.floor(hallH / 4.2)}>
          <Box
            className='item'
            sx={{
              background: `url(${Index2})`,
              clipPath: `polygon(0 0, 100% 0, ${width / 2 - hallH / 4.2}px 100%, 0% 100%)`
            }}
          >
            <Box pl={10} className='content'>
              <Typography variant='h6' mb={2}>
                Art Blocks Curated Hall
              </Typography>
              <Box className='divider' />
              <Typography variant='h6' mb={2}>
                00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                <span className='date'>mins</span>
                :00
                <span className='date'>secs</span>
              </Typography>
              <Typography variant='subtitle2'>Information</Typography>
              <Typography variant='subtitle2'>Session: A01</Typography>
              <Typography variant='subtitle2'>Booth: 100</Typography>
              <Typography variant='subtitle2'>Exhibits: 0</Typography>
              <Box textAlign='center'>
                <Button variant='outlined' color='inherit' component={NavLink} to='/hall/1'>
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width={width / 2 - Math.floor(hallH / 4.2) - 1}>
          <Box
            className='item'
            sx={{
              background: `url(${Index3})`,
              clipPath: `polygon(${hallH / 2.1}px 0, 100% 0, 100% 100%, 0% 100%)`,
              ml: `-${hallH / 2}px`
            }}
          >
            <Box pl={`${hallH / 2}px`} className='content'>
              <Typography variant='h6' mb={2}>
                Bored Ape Yacht Club Hall
              </Typography>
              <Box className='divider' />
              <Typography variant='h6' mb={2}>
                00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                <span className='date'>mins</span>
                :00
                <span className='date'>secs</span>
              </Typography>
              <Typography variant='subtitle2'>Information</Typography>
              <Typography variant='subtitle2'>Session: A01</Typography>
              <Typography variant='subtitle2'>Booth: 100</Typography>
              <Typography variant='subtitle2'>Exhibits: 0</Typography>
              <Box textAlign='center'>
                <Button variant='outlined' color='inherit' component={NavLink} to='/hall/2'>
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          '.item': {
            height: `${hallH}px`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '102%',
            cursor: 'pointer',
            position: 'relative',
            '&:hover': {
              '& .content': {
                opacity: 1,
                visibility: 'visible'
              }
            },
            '& .content': {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: '#000000',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 100,
              opacity: 0,
              visibility: 'hidden'
            }
          }
        }}
      >
        <Grid item xs={4}>
          <Box
            className='item'
            sx={{
              background: `url(${Index4})`,
              clipPath: `polygon(0 0, ${width / 3 - hallH / 5}px 0, 100% 100%, 0% 100%)`
            }}
          >
            <Box pl={10} className='content'>
              <Typography variant='h6' mb={2}>
                Loot Hall
              </Typography>
              <Box className='divider' />
              <Typography variant='h6' mb={2}>
                00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                <span className='date'>mins</span>
                :00
                <span className='date'>secs</span>
              </Typography>
              <Typography variant='subtitle2'>Information</Typography>
              <Typography variant='subtitle2'>Session: A01</Typography>
              <Typography variant='subtitle2'>Booth: 100</Typography>
              <Typography variant='subtitle2'>Exhibits: 0</Typography>
              <Box textAlign='center'>
                <Button variant='outlined' color='inherit' component={NavLink} to='/hall/3'>
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className='item'
            sx={{
              background: `url(${Index5})`,
              clipPath: `polygon(0 0, ${width / 3}px 0, 100% 100%, ${hallH / 5}px 100%)`,
              ml: `-${hallH / 5}px`
            }}
          >
            <Box pl={`${hallH / 5 + 30}px`} className='content'>
              <Typography variant='h6' mb={2}>
                Meebits Hall
              </Typography>
              <Box className='divider' />
              <Typography variant='h6' mb={2}>
                00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                <span className='date'>mins</span>
                :00
                <span className='date'>secs</span>
              </Typography>
              <Typography variant='subtitle2'>Information</Typography>
              <Typography variant='subtitle2'>Session: A01</Typography>
              <Typography variant='subtitle2'>Booth: 100</Typography>
              <Typography variant='subtitle2'>Exhibits: 0</Typography>
              <Box textAlign='center'>
                <Button variant='outlined' color='inherit' component={NavLink} to='/hall/4'>
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className='item'
            sx={{
              background: `url(${Index6})`,
              clipPath: `polygon(0 0, 100% 0, 100% 100%, ${hallH / 5}px 100%)`,
              ml: `-${hallH / 5 + 20}px`
            }}
          >
            <Box pl={`${hallH / 5 + 30}px`} className='content'>
              <Typography variant='h6' mb={2}>
                Free Hall
              </Typography>
              <Box className='divider' />
              <Typography variant='h6' mb={2}>
                00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                <span className='date'>mins</span>
                :00
                <span className='date'>secs</span>
              </Typography>
              <Typography variant='subtitle2'>Information</Typography>
              <Typography variant='subtitle2'>Session: A01</Typography>
              <Typography variant='subtitle2'>Booth: 100</Typography>
              <Typography variant='subtitle2'>Exhibits: 0</Typography>
              <Box textAlign='center'>
                <Button variant='outlined' color='inherit' component={NavLink} to='/hall/5'>
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Halls
