import React from 'react'
import {Box, Typography} from '@mui/material'
import Github from '../../images/github.svg'
import Discord from '../../images/discord.svg'
import Twitter from '../../images/twitter.svg'
import Instagram from '../../images/instagram.svg'

const Footer = () => {
  return (
    <Box py={16} px={4} textAlign='center'>
      <Typography variant='h2' mb={4} fontSize={{md: '54px', xs: '36px'}}>
        Join our community
      </Typography>
      <Box px={{md: 70, xs: 4}}>
        <Typography variant='h6' fontSize={{md: '20px', xs: '18px'}}>
          Chat with the team and others in the community to learn more about FLEX and help shape the future of crypto
          arts.
        </Typography>
      </Box>
      <Box
        mt={8}
        sx={{
          svg: {
            margin: '0 20px'
          },
          a: {
            display: 'inline-block',
            margin: {md: '0 46px', xs: '0 15px'}
          }
        }}
      >
        
        <a href='https://github.com/FlEX-NFT' target='_blank'>
          <img src={Github} alt='Github' width={40} />
        </a>
        <a href='https://discord.com/channels/887661511772893233/887661512225869854' target='_blank'>
          <img src={Discord} alt='Discord' width={40} />
        </a>
        <a href='https://twitter.com/theFlexArt' target='_blank'>
          <img src={Twitter} alt='Twitter' width={40} />
        </a>
        <a href='https://www.instagram.com/theflexart/' target='_blank'>
          <img src={Instagram} alt='Instagram' width={40} />
        </a>
      </Box>
    </Box>
  )
}

export default Footer
